import React, { useContext, useEffect } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from '@chakra-ui/react'
import ColorContext from '../context/ColorContext'
import { hexToRGB } from '../theme/themeAlopex'

const FormModal = ({ portalId, formId }) => {
  console.log('husbpot', portalId, formId)
  const { color } = useContext(ColorContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const script = document.createElement('script');
    script.src='https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm'
        })
      }
    });
  }, [isOpen]);

  return (
    <>
      <Button className="morphicShadow" variant="buttonMorphicSm" cursor="pointer" onClick={onOpen} color={color.alpha}>Message</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="xl" autoFocus={false} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton backgroundColor={hexToRGB('#37474f', 0.05)} color="#37474f" _hover={{backgroundColor: hexToRGB(color.alpha, 0.3)}} _focus={{boxShadow: 'none'}} />
          <ModalBody p="2rem">
            <ModalHeader lineHeight="1" p="0" fontSize="4xl" mb="1rem" fontWeight="400">Message Us</ModalHeader>
            <Box id="hubspotForm" />
          </ModalBody>
        </ModalContent>
      </Modal>
      <style>{`
        .chakra-modal__overlay {
          background-color: rgba(255, 255, 255, 0.65);
          backdrop-filter: blur(4px);
          -webkit-backdrop-filter: blur(4px);
        }
        .chakra-modal__content {
          border-top: 2px solid white;
          border-left: 2px solid white;
          border-radius: 20px;
          background-color: #f1f1f1;
          box-shadow: -4px -4px 10px 1px white, 4px 4px 12px 1px #a7b3b9;
          color: #37474f;
        }
      `}</style>
    </>
  )
}

export default FormModal