import React, { useContext, useEffect, useState } from 'react'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import FooterPioneer from '../components/Footer-Pioneer'
import {
  Box,
  Flex,
  Grid,
  Container,
  Button,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import { spacing, breakpoints, colors, hexToRGB, typography } from "../theme/themeAlopex"
import { H1, H2, H3, H4, H6 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
// import AlopexLogo from '../components/AlopexLogo'
import AKLogosNew from '../components/AKLogosNew'
import AKLogosMobileNew from '../components/AKLogosMobileNew'
// Clients
// import Clients from '../components/Clients'
import Monitoring from '../components/Services/Tiers/Monitoring'
import Enhanced from '../components/Services/Tiers/Enhanced'
import Premium from '../components/Services/Tiers/Premium'
import Marquee from '../components/Marquee'
import VideoBox from '../components/VideoBox'
// import ClientLogo from '../components/ClientLogo'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/all'
import Masonry from '../components/Masonry'
import HubSpot from '../components/HubSpot'
import FormModal from '../components/FormModal'
// Register gsap plugins
gsap.registerPlugin(ScrollToPlugin)

const  ServicePost2024 = ({ data }) => {
  const { page, settings } = data
  const { color } = useContext(ColorContext)
  const [showCTAButtons, setShowCTAButtons] = useState(false)

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": 
    page.listFAQ.map((faq, i) => {
      return i = 0 ? i ({
        "@type": "Question",
        "name":  faq.faqQuestion,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.faqAnswer
        }
      }
      
      ) : ({
        "@type": "Question",
        "name": faq.faqQuestion,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.faqAnswer
        }
      })
    })
  }

  const [isSafari, setIsSafari] = useState(false)
  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
    }
  }, [])
    
  const [isDesktop, setDesktop] = useState(false)
  const [gutter, setGutter] = useState(0)
  const updateMedia = () => {
    if (window.innerWidth > 960) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
    if (window.innerWidth > 1200) {
      setGutter((window.innerWidth - 1200) / 2)
    }
  }

  useEffect(() => {
    updateMedia()
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  const handleScroll = (e, anchor) => {
    e.preventDefault()
    gsap.to(window, {
      duration: 1,
      scrollTo: anchor,
      ease: 'power1.out'
    })
  }

  const handleCTAButtons = () => {
    const tl = gsap.timeline()
    tl.to('.btnGS', {opacity: 0, x: 100, pointerEvents: 'none', duration: 0.1, ease: 'power1.out'})
    tl.fromTo('.btnCTAs', {x: -100}, {opacity: 1, x: 0, pointerEvents: 'auto', duration: 0.15, delay: 0.1, ease: 'power1.inOut'})
  }

  const ogImage = !!page.seoFields.ogImage ? `https://www.alopexid.com${page.seoFields.ogImage.replace('/static', '')}` : null
  const schemaMarkUp = !!page.listFAQ ? schema : null

  let vid
  if (!!page.serviceVideoUpload) {
    vid = <VideoBox videoURL={page.serviceVideoUpload} videoThumb={page.vidThumb} videoCCTrack={page.serviceVideoCCTrack} />
  } else if (!!page.vidImg) {
    vid = <Box as="img" src={page.vidImg.replace('/static', '')} alt={page?.title} style={{borderRadius: '20px'}}
      boxShadow={`
        0.7px 0.7px 0.9px -3px rgba(0, 0, 0, 0.034),
        1.7px 1.7px 2.1px -3px rgba(0, 0, 0, 0.048),
        3.1px 3.1px 4px -3px rgba(0, 0, 0, 0.06),
        5.6px 5.6px 7.1px -3px rgba(0, 0, 0, 0.072),
        10.4px 10.4px 13.4px -3px rgba(0, 0, 0, 0.086),
        25px 25px 32px -3px rgba(0, 0, 0, 0.12)
      `}
    />
  } else if (!!page.vidSpline) {
    vid = <div className="splineBox" dangerouslySetInnerHTML={{ __html: page.vidSpline }} />
  } else {
    // vid = <AlopexLogo fill={color.alpha} />
    vid = <div className="splineBox" dangerouslySetInnerHTML={{ __html: '<iframe src="https://my.spline.design/PMcrz3g6C-TlVDF660-qKouK/" frameBorder="0" width="100%" height="100%"></iframe>' }} />
  }

  return (
    <Layout bgColor="#f1f1f1">
      <SEO title={page?.seoFields?.seoTitle} description={page.seoFields?.seoDesc} schemaMarkUp={schemaMarkUp} ogImage={ogImage}/>
        {/* HEADER VIDEO & CTA */}
        <Box position="relative" background={`linear-gradient(135deg, ${hexToRGB(color.alpha, 0.8)}, ${hexToRGB(color.bravo, 0.35)})`} marginTop="45px" mb={[spacing.groups, null, spacing.section]}
          sx={{
            '&::before': {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              height: '100%',
              width: '100%',
              background: `linear-gradient(180deg, transparent, #f1f1f1)`
            }
          }}
        >
          <Container
            position="relative"
            maxWidth={breakpoints.xl}
            padding={[`1rem 1rem`, null, null, null, '135px 1rem 0']}
            display={[null, null, 'grid']}
            gridTemplateColumns={[null, null, '1fr 1fr']}
            gridGap="4rem"
          >
            {/* VIDEO */}
            <Flex alignItems="center" justifyContent="center"
              sx={{
                '#AlopexLogoSVG': {
                  maxWidth: '160px',
                  paddingTop: '30px',
                  width: '100%',
                  '@media (min-width: 640px)': {
                    maxWidth: '250px',
                    paddingTop: 0
                  }
                },
                '.splineBox': {
                  width: '100%',
                  height: '80vw',
                  minHeight: '600px',
                  '@media (min-width: 640px)': {
                    height: '100%'
                  }
                }
              }}
            >
              {vid}
            </Flex>
            {/* CTA */}
            <Flex flexDirection="column" maxW="100%" justifyContent="center">
              <Container
                maxWidth={breakpoints.xl}
                textAlign={['center', null, 'left']}
                color={colors.brand.white}
                margin={[`60px 0 100px`, null, `60px 0 ${spacing.elements}`, `${spacing.elements} 0 0`]}
                pl="0"
                pr="0"
                sx={{
                  'a': {
                    color: color.alpha,
                  }
                }}
                order={[null, null, 3]}
                position="relative"
              >
                <Button display="inline-block" as="a" className="morphicShadow btnGS" onClick={handleCTAButtons} variant="buttonMorphicSm" cursor="pointer">Get Started</Button>
                <Flex top="0" left="0" position="absolute" display="flex" justifyContent={['center', null, null, 'flex-start']} opacity="0" className="btnCTAs" pointerEvents="none" w="100%">
                  <Flex
                    gridGap="14px"
                    justifyContent={['center', null, 'flex-start']}
                    flexWrap="wrap"
                  >
                    {!!page.hsFormId ? (
                      <FormModal portalId={page?.hsPortalId} formId={page?.hsFormId} />
                    ) : ( 
                      <Button as="a" className="morphicShadow" variant="buttonMorphicSm" cursor="pointer" href="/contact" target="_blank">Message</Button>
                    )}
                    <Button as="a" className="morphicShadow" onClick={(e) => {handleScroll(e, '#schedule-meeting');}} variant="buttonMorphicSm" cursor="pointer">Schedule</Button>
                    <Button display={['block', null, 'none']} as="a" className="morphicShadow" href={`sms:${settings?.phone}`} variant="buttonMorphicSm">Text</Button>
                    <Button as="a" className="morphicShadow" href={`tel:${settings?.phone}`} variant="buttonMorphicSm">Call</Button>
                  </Flex>
                </Flex> 
              </Container>
              
              <Box maxWidth={breakpoints.md}>
                <H1 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)} maxW="450px">{page?.hdrSuperTitle}</H1>
                <H2 variant="figtree">{page?.hdrTitle}</H2>

                <Box as="p" mb="0">{page?.hdrIntroP}</Box>
              </Box>
            </Flex>
          </Container>
        </Box>

        {/* Kicker */}
        {!!page?.kicker &&
          <Container
            maxWidth={breakpoints.xl}
            mb={[spacing.groups, null, spacing.section]}
            sx={{
              'h1, h2, h3, h4, h5, h6': {
                fontFamily: typography.fonts.body,
                color: 'white',
                position: 'relative',
                fontWeight: '400',
                background: `linear-gradient(to right, ${hexToRGB(color.alpha, 0.7)}, ${hexToRGB(color.bravo, 1)})`,
                textShadow: '0px 1px 2px rgba(255,255,255,0.5)',
                backgroundClip: 'text',
                textTransform: 'none',
                textAlign: 'center',
                a: {
                  color: hexToRGB(color.alpha, 0.5),
                  '&:hover': {
                    color: color.bravo
                  }
                }
              }
            }}
          >
            <ReactMarkdown remarkPlugins={[gfm]} children={page?.kicker} />
          </Container>
        }

        {/* PORTFOLIO */}
        {page?.port &&
        <>
          <Box overflow="hidden" borderBottom="2px solid white" borderTop={`1px solid ${hexToRGB(colors.brand.slate, 0.3)}`} position="relative" height="640px"
            sx={{
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0, left: 0,
                width: '100%',
                height: '10px',
                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.15), transparent)',
                zIndex: 1
              },
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0, left: 0,
                width: '100%',
                height: '10px',
                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15), transparent)',
                zIndex: 1
              }
            }}
          >
            <Box overflow="hidden" transform="translateX(-5%) rotate(-2deg)" width="110%" position="relative" top="-64px">
              <Box
                width="11520px"
                height="768px"
                backgroundImage={`url(${page.port.replace('/static', '')})`}
                backgroundSize="contain"
                animation="slide 65s linear infinite"
              />
            </Box>
          </Box>
          <Container maxWidth={breakpoints.xl} mb={[spacing.groups, null, spacing.section]} mt="-125px" position="relative" zIndex="10">
            <Box
              maxWidth="600px"
              boxShadow={`2px 2px 5px ${hexToRGB(colors.brand.slate, 0.2)}`}
              backgroundColor={hexToRGB('#f1f1f1', 0.9)}
              borderRadius="10px"
              padding="2rem"
              marginLeft="auto"
              borderTop="1px solid white"
              borderLeft="1px solid white"
              sx={{
                'backdrop-filter': 'blur(5px)',
              }}
            >
              <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.portSuperTitle}</H2>
              <H2 variant="figtree">{page?.portTitle}</H2>
              <Box as="p">{page?.portDescription}</Box>

              <H6 fontWeight="bold">Schedule a call or visit with Kyle or Bobby.</H6>
              <Box as="p">We have same-day availability!</Box>
              <Flex flexDirection={['column', 'row']} margin="0 auto" alignItems="center" gridGap="10px">
                <Box maxH="50px">
                    <Box display="inline-block" backgroundColor={color.alpha} width="50px" height="50px" borderRadius="999px" boxShadow="1px 1px 5px #aaa"
                      backgroundImage="url(/images/avatar-bobby.png)" backgroundSize="cover" backgroundPosition="center"
                    />
                    <Box display="inline-block" backgroundColor={color.bravo} width="50px" height="50px" borderRadius="999px" marginLeft="-10px" boxShadow="1px 1px 5px #aaa"
                      backgroundImage="url(/images/avatar-kyle.png)" backgroundSize="cover" backgroundPosition="center"
                    />
                </Box>
                <Box as="a" className="buttonGradient" onClick={(e) => {handleScroll(e, '#schedule-meeting');}}
                  cursor="pointer"
                  backgroundImage={`linear-gradient(to right, ${hexToRGB(color.alpha, 1)} 0%, ${hexToRGB(color.alpha, 0.35)} 33%, ${hexToRGB(color.bravo, 0.35)} 66%, ${color.bravo} 100%)`}
                >
                  Schedule a Chat
                </Box>
              </Flex>

            </Box>
          </Container>
        </>
        }

        {/* REASONS */}
        <Masonry reasons={page?.reasons} photoCats={!!page?.photoCats ? page.photoCats : ['Culture', 'Office', 'Dev', 'Creative', 'Media Capture']}/>


        {/* OPTIONAL PRICING TIERS */}
        {page?.priceTiersShow &&
          <Container maxWidth={breakpoints.xl} mb={[spacing.groups, null, spacing.section]}>
            <SimpleGrid
              gap={spacing.icon}
              columns={[1, null, 3]}
              sx={{
                '.tierBox': {
                  backgroundColor: color.alpha,
                  borderRadius: '10px',
                  padding: '2px',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: `0 2px 10px ${hexToRGB(colors.brand.slate, 0.4)}`
                  },
                  '& .whiteBox': {
                    backgroundColor: hexToRGB('#ffffff', 0.95),
                    borderRadius: '8px',
                    padding: '50px 30px',
                    '.tierIcon': {
                      maxWidth: '90px',
                      color: color.alpha,
                      marginBottom: spacing.elements
                    },
                    '& h3': {
                      color: color.alpha,
                      marginBottom: spacing.icon
                    },
                    'ul': {
                      marginBottom: '0',
                      'li': {
                        '&:not(:last-of-type)': {
                          marginBottom: '20px'
                        },
                        '&::marker': {
                          color: color.alpha
                        }
                      }
                    }
                  },
                  '&.tb02': {
                    backgroundColor: color.bravo,
                    '& .tierIcon': {
                      color: color.bravo
                    },
                    'ul li::marker': {
                      color: color.bravo
                    },
                    '& h3': {
                      color: color.bravo
                    }
                  },
                  '&.tb03': {
                    backgroundImage: `linear-gradient(135deg, ${color.alpha}, ${color.bravo})`,
                    '& h3': {
                      color: color.alpha,
                      display: 'inline-block',
                      background: `-webkit-linear-gradient(0deg, ${color.alpha}, ${color.bravo})`,
                      'WebkitBackgroundClip': 'text',
                      'WebkitTextFillColor': 'transparent',
                      backgroundClip: 'text',
                      textFillColor: 'transparent'
                    }
                  },
                  'h4': {
                    textAlign: 'center',
                    padding: spacing.icon,
                    fontWeight: 'bold',
                    color: 'white',
                    fontSize: '22px'
                  }
                }
              }}
            >
              {page?.priceTiers.length > 0 && page?.priceTiers.map((tier, i) => {
                return (
                  <Box key={`key-priceTiers-${i}`}>
                    <Box className={`tierBox tb0${i+1}`}>
                      <Box className="whiteBox">
                        <Box className="tierIcon">
                          {i === 0 &&
                            <Monitoring />
                          }
                          {i === 1 &&
                            <Enhanced />
                          }
                          {i === 2 &&
                            <Premium stop1={color.alpha} stop2={color.bravo} />
                          }
                        </Box>
                        <H3>{tier?.priceTierTitle}</H3>
                        <ReactMarkdown remarkPlugins={[gfm]} children={tier?.priceTierMDX} />
                      </Box>
                      <H4>{tier?.priceTierPrice}</H4>
                    </Box>
                  </Box>
                )
              })}
            </SimpleGrid>
          </Container>
        }

        {/* WHO IS ALOPEX? */}
        {isDesktop && (
          <Box position="relative" mb={[spacing.groups, null, spacing.section]}>
            <Box position="absolute" left="0" top="50%" transform="translateY(calc(-50% - 0.5px))" width="100%">
              <Box borderTop={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`} borderBottom={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`}>
                <Marquee direction="left" displayed="9"/>
              </Box>
              <Box borderBottom={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`}>
                <Marquee direction="right" displayed="9"/>
              </Box>
            </Box>
            <Container maxWidth={breakpoints.xl} position="relative"
              sx={{
                '.whoIsWrapper': {
                  backdropFilter: 'blur(5px)',
                }
              }}
            >
              <Box
                className="whoIsWrapper"
                maxWidth="600px"
                backgroundColor="rgba(241, 241, 241, 0.9)"
                borderTop="1px solid white"
                borderLeft="1px solid white"
                borderRadius="20px"
                padding="2rem"
                boxShadow={`2px 2px 5px ${hexToRGB(colors.brand.slate, 0.2)}`}
              >
                <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>Who is Alopex?</H2>
                <H2 variant="figtree">Results-Focused, Multidisciplinary, Approachable.</H2>

                <Box as="p" maxW="420px">We're a team of creatives and programmers who are focused on building custom solutions for our clients.</Box>
              </Box>
            </Container>
          </Box>
        )}
        {!isDesktop && (
          <>
            <Container>
              <Box maxWidth={breakpoints.md}>
                <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.s2SuperTitle}</H2>
                <H2 variant="figtree">{page?.s2Title}</H2>

                <p>{page?.s2Blurb}</p>
              </Box>
            </Container>
            <Box borderTop={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`} borderBottom={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`}>
              <Marquee direction="left"/>
            </Box>
            <Box borderBottom={`1px solid ${hexToRGB(colors.brand.slate, 0.5)}`} mb={[spacing.groups, null, spacing.section]}>
              <Marquee direction="right"/>
            </Box>
          </>
        )}


        {/* TRUSTED BY OUR CLIENTS - Desktop */}
        {isDesktop && (
          <Container maxWidth={breakpoints.xl} mb={[spacing.groups, null, spacing.section]}>
            <Grid gridTemplateColumns="1.25fr 2fr" gridGap="2rem">
              <Box mb="20px"
                sx={{
                  span: {
                    fontFamily: typography.fonts.body,
                    color: 'white',
                    position: 'relative',
                    fontWeight: '400',
                    background: `linear-gradient(135deg, ${hexToRGB(color.alpha, 0.7)}, ${hexToRGB(color.bravo, 1)})`,
                    textShadow: '0px 1px 2px rgba(255,255,255,0.5)',
                    backgroundClip: 'text',
                    textTransform: 'none',
                    display: 'inline-block'
                  }
                }}
              >
                <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.s3SuperTitle}</H2>
                <H2 variant="figtree">{page?.s3Title}</H2>
                <Box fontSize="25px" fontWeight="300" lineHeight="1.2" mb="0.625rem" color={hexToRGB(colors.brand.slate, 0.3)}>
                  <span>
                    &ldquo;{page?.s3Quote}&rdquo;
                  </span>
                </Box>
                <Box as="p" fontWeight="600" mb="2rem" color={hexToRGB(colors.brand.slate, 0.3)}><small>&mdash;{page?.s3Attr}</small></Box>
                {!!page?.s3Cases && page.s3Cases.map((study, i) => {
                  return (
                    <Flex key={`s3case-key-${i}`} backgroundColor="#e9e9e9" borderTopRightRadius="10px" borderBottomRightRadius="10px" gridGap="0.5rem" mb="12px">
                      <Box
                        as="img"
                        src={study?.image.replace('/static', '')}
                        maxW="100px"
                        width="100%"
                        alt="K2 Aviation"
                      />
                      <Flex flexDirection="column" justifyContent="center" p="0.5rem">
                        <H6 mb="5px" fontSize="0.75rem"><strong>{study?.title}</strong></H6>
                        <Box as="p" mb="0" fontSize="0.875rem">{study?.blurb}</Box>
                      </Flex>
                    </Flex>
                  )
                })}
              </Box>
              <Box m={[null, null, null, null, '0 0 0 -120px', `-100px -${gutter}px 0 -120px`]}>
                <AKLogosNew />
              </Box>
            </Grid>
          </Container>
        )}
        {/* TRUSTED BY OUR CLIENTS - Mobile */}
        {!isDesktop && (
          <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
            <Box maxWidth={breakpoints.md} mb="20px">
              <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.s3SuperTitle}</H2>
              <H2 variant="figtree">{page?.s3Title}</H2>
              <Box fontSize="25px" mb="0.625rem" fontWeight="300" color={hexToRGB(colors.brand.slate, 0.3)}
                sx={{
                  span: {
                    fontFamily: typography.fonts.body,
                    color: 'white',
                    position: 'relative',
                    fontWeight: '400',
                    background: `linear-gradient(135deg, ${hexToRGB(color.alpha, 0.7)}, ${hexToRGB(color.bravo, 1)})`,
                    textShadow: '0px 1px 2px rgba(255,255,255,0.5)',
                    backgroundClip: 'text',
                    textTransform: 'none',
                    display: 'inline-block'
                  }
                }}         
              >
                <span>&ldquo;{page?.s3Quote}&rdquo;</span>
              </Box>
              <Box as="p" fontWeight="600" mb={spacing.elements} color={hexToRGB(colors.brand.slate, 0.3)}><small>&mdash;{page?.s3Attr}</small></Box>
              <AKLogosMobileNew />
            </Box>
                {!!page?.s3Cases && page.s3Cases.map((study, i) => {
                  return (
                    <Flex key={`s3case-mob-key-${i}`} backgroundColor="#e9e9e9" borderRadius="10px" overflow="hidden" gridGap="0.5rem" mb="12px">
                      <Box
                        as="img"
                        src={study?.image.replace('/static', '')}
                        maxW="100px"
                        width="100%"
                        alt="K2 Aviation"
                      />
                      <Flex flexDirection="column" justifyContent="center" p="0.5rem">
                        <H6 mb="5px" fontSize="0.75rem"><strong>{study?.title}</strong></H6>
                        <Box as="p" mb="0" fontSize="0.875rem">{study?.blurb}</Box>
                      </Flex>
                    </Flex>
                  )
                })}
          </Container>
        )}

        {/* Schedule CTA */}
        <Container maxWidth={breakpoints.lg} mb={[spacing.groups, null, spacing.section]}>
          <H6 textAlign="center" fontWeight="bold">Schedule a call or visit with Kyle or Bobby.</H6>
          <Box as="p" textAlign="center">We have same-day availability!</Box>
          <Flex flexDirection={['column', 'row']} margin="0 auto" alignItems="center" justifyContent="center" gridGap="10px">
            <Box maxH="50px">
                <Box display="inline-block" backgroundColor={color.alpha} width="50px" height="50px" borderRadius="999px" boxShadow="1px 1px 5px #aaa"
                  backgroundImage="url(/images/avatar-bobby.png)" backgroundSize="cover" backgroundPosition="center"
                />
                <Box display="inline-block" backgroundColor={color.bravo} width="50px" height="50px" borderRadius="999px" marginLeft="-10px" boxShadow="1px 1px 5px #aaa"
                  backgroundImage="url(/images/avatar-kyle.png)" backgroundSize="cover" backgroundPosition="center"
                />
            </Box>
            <Box as="a" className="buttonGradient" onClick={(e) => {handleScroll(e, '#schedule-meeting');}}
              cursor="pointer"
              backgroundImage={`linear-gradient(to right, ${hexToRGB(color.alpha, 1)} 0%, ${hexToRGB(color.alpha, 0.35)} 33%, ${hexToRGB(color.bravo, 0.35)} 66%, ${color.bravo} 100%)`}
            >
              Schedule a Chat
            </Box>
          </Flex>
        </Container>




        <Box
          position="relative"
          backgroundColor="white"
          _before={{
            content: '""',
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '300px',
            background: 'linear-gradient(180deg, #f1f1f1, transparent)',
            zIndex: '5'
          }}
          _after={{
            content: '""',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
            height: '600px',
            background: 'linear-gradient(0deg, #f1f1f1, transparent)',
            zIndex: '5'
          }}
        >
          <Box
            position="sticky"
            width="100%"
            height="100%"
            top="0"
            display={['none', null, null, null, 'block']}
          >
            <Box
              position="absolute"
              left="90px"
              top="200px"
              width="350px"
              height="350px"
              backgroundColor="white"
              opacity={[null, null, null, null, '0.15', '0.25']}
              _after={{
                content: '""',
                position: 'absolute',
                inset: 0,
                backgroundImage: `linear-gradient(135deg, ${color.alpha} 20%, ${color.bravo})`,
                mixBlendMode: 'color',
                zIndex: 3,
                display: isSafari ? 'none' : null
              }}
            >
              <iframe src="https://my.spline.design/PMcrz3g6C-TlVDF660-qKouK/" frameBorder="0" width="100%" height="100%" style={{filter: 'blur(5px)', position: 'relative', zIndex: 2}}></iframe>
            </Box>
            <Box
              position="absolute"
              right="0"
              top="290px"
              width="500px"
              height="500px"
              backgroundColor="white"
              opacity={[null, null, null, null, '0.5', '0.75']}
              _after={{
                content: '""',
                position: 'absolute',
                inset: 0,
                backgroundImage: `linear-gradient(135deg, ${color.alpha} 20%, ${color.bravo})`,
                mixBlendMode: 'color',
                zIndex: 3,
                display: isSafari ? 'none' : null
              }}
            >
              <iframe src="https://my.spline.design/PMcrz3g6C-TlVDF660-qKouK/" frameBorder="0" width="100%" height="100%" style={{position: 'relative', zIndex: 2}}></iframe>
            </Box>
          </Box>






          <Box position="relative" zIndex="10">
            {/* Sub Services */}
            <Container maxWidth="850px" mb={[spacing.groups, null, spacing.section]}>
              <Box maxWidth={breakpoints.md} mb="30px">
                <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.s5SuperTitle}</H2>
                <H2 variant="figtree">{page?.s5Title}</H2>
              </Box>
              <Accordion
                allowToggle
                maxW="850px"
                sx={{
                  '.accItem': {
                    background: 'linear-gradient(180deg, #f1f1f1, #ffffff)',
                    marginBottom: '1rem',
                    borderRadius: '20px',
                    position: 'relative',
                    border: 'none',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      inset: '-1px',
                      boxShadow: '-4px -4px 10px 1px white, 4px 4px 12px 1px #a7b3b9',
                      background: `linear-gradient(to right, ${hexToRGB(color.alpha, 0.15)}, ${hexToRGB(color.alpha, 1)})`,
                      borderRadius: '21px',
                      zIndex: '-1'
                    },
                    button: {
                      padding: '1.5rem'
                    },
                    h4: {
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      fontSize: '1.25rem',
                    },
                    p: {
                      maxWidth: '600px'
                    },
                    '.accPanel': {
                      padding: '0.25rem 1.5rem 1.25rem 1.5rem'
                    }
                  }
                }}
              >
                {!!page?.listServices && page.listServices.map((svc, i) => {
                  return (
                    <React.Fragment key={`key-acc-svc-${i}`}>
                      <AccordionItem className="accItem">
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton _focus={{boxShadow: 'none'}} _hover={{backgroundColor: 'transparent'}} backgroundColor="transparent">
                              <H4 mb="0" width="100%" lineHeight="1" color={color.alpha}>
                                <Flex flex="1" textAlign="left" justifyContent="space-between" alignItems="center" gridGap="1rem">
                                  {svc?.title}
                                  {isExpanded ? (
                                    <Flex justifyContent="center" alignItems="center" lineHeight="1" fontSize="35px" width="31px" height="31px" minWidth="31px" backgroundColor={color.alpha} borderRadius="999px" color="#f1f1f1" fontWeight="100">
                                      <svg viewBox="0 0 12 12" width="14px" height="14px">
                                        <path d="M11.382,6.595H.595c-.331,0-.595-.265-.595-.595s.265-.595.595-.595h10.809c.331,0,.595.265.595.595-.022.331-.287.595-.618.595h0Z" fill="#fff" fillRule="evenodd" strokeWidth="0"/>
                                      </svg>
                                    </Flex>
                                  ) : (
                                    <Flex justifyContent="center" alignItems="center" lineHeight="1" fontSize="35px" width="31px" height="31px" minWidth="31px" backgroundColor={color.alpha} borderRadius="999px" color="#f1f1f1" fontWeight="100">
                                      <svg viewBox="0 0 12 12" width="14px" height="14px">
                                        <path d="M11.382,6.595h-4.808v4.809c0,.331-.265.595-.595.595s-.595-.265-.595-.595v-4.809H.595c-.331,0-.595-.265-.595-.595s.265-.595.595-.595h4.809V.595c0-.331.265-.595.595-.595s.595.265.595.595v4.809h4.809c.331,0,.595.265.595.595-.022.331-.287.595-.618.595h0Z" fill="#fff" fillRule="evenodd" strokeWidth="0"/>
                                      </svg>
                                    </Flex>
                                  )}
                                </Flex>
                              </H4>
                            </AccordionButton>
                            <AccordionPanel className="accPanel">
                              <ReactMarkdown remarkPlugins={[gfm]} children={svc?.mdxService} />
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </React.Fragment>
                  )
                })}
              </Accordion>
            </Container>

            {/* Our Process */}
            {!!page?.s4Title &&
              <Container maxWidth="850px" mb={['70px', null, '165px']}>
                <Box maxWidth={breakpoints.md}>
                  <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.s4SuperTitle}</H2>
                  <H2 variant="figtree">{page?.s4Title}</H2>
                </Box>
                {/* Numbers */}
                <Box maxWidth="850px"
                  sx={{
                    '.bigNum, .bigNum span': {
                      fontSize: '9rem',
                      lineHeight: '8rem',
                      textShadow: '-5px -5px 8px rgb(255 255 255), 6px 6px 12px rgba(55, 71, 79, 0.2), 3px 3px 8px rgba(55, 71, 79, 0.25)',
                      fontFamily: typography.fonts.body,
                      fontWeight: '500',
                      color: 'white',
                      position: 'relative',
                      span: {
                        position: 'absolute',
                        textShadow: 'none',
                        left: 0,
                        top: 0,
                        color: 'rgba(0, 0, 0, 0.1)',
                        background: `linear-gradient(180deg, #f0f0f0, #e5e5e5)`,
                        backgroundClip: 'text',
                      },
                    },
                    '.bigNum::before': {
                      content: '""',
                      position: 'absolute',
                      inset: 0,
                      backgroundColor: '#f1f1f1',
                      borderRadius: '999px',
                      boxShadow: '0 0 70px 50px #f1f1f1',
                      zIndex: -1
                    },
                    '.bigBlurb': {
                      maxWidth: '400px',
                      marginBottom: '0'
                    }
                  }}
                >
                  {!!page?.s4Numbers && page.s4Numbers.map((step, i) => {
                    return (
                      <Flex key={`s4numbers-key-${i}`} gridGap={spacing.icon} alignItems="center" mb={spacing.icon} justifyContent={i % 2 ? 'flex-end' : null}>
                        <Box className="bigNum" flex="0 0 95px">{step?.dropCap}<span>{step.dropCap}</span></Box>
                        <Box>
                          <H6 color={color.alpha} fontWeight="600">{step?.title}</H6>
                          <Box as="p" className="bigBlurb">
                            {step?.blurb}
                          </Box>
                        </Box>
                      </Flex>
                    )
                  })}
                </Box>
              </Container>
            }

            {/* HubSpot */}
            <a id="schedule-meeting"></a>
            <Box mb={[spacing.groups, null, spacing.section]} overflow="hidden">
              <HubSpot
                embedURL={!!page?.hubspot ? page.hubspot : 'https://meetings.hubspot.com/alopexkyle/inbound-meeting?embed=true'}
                superTitle={!!page?.hubspotSuperTitle ? page.hubspotSuperTitle : "Let's Chat"}
                title={!!page?.hubspotTitle ? page.hubspotTitle : "Come get a coffee, or join us online."}
                blurb={!!page?.hubspotBlurb ? page.hubspotBlurb : "No matter where your SEO is at, we are happy to have a conversation with you. Even if we aren't the fit for you, we enjoy meeting new people and hearing about their businesses and ideas.\n\n Owner Kyle Fox and Account Executive Bobby Burns work directly with clients to ensure their success. Schedule a meeting using this tool and we'll be notified immediately.\n\n If you need to schedule a meeting further in advance, then please use our [inquiry](/contact) form."}
              />
            </Box>

            {/* FAQ */}
            {!!page?.listFAQ && page.listFAQ.length >= 1 &&
              <Container maxWidth="850px" pb={[spacing.elements]}>
                <Box
                  maxWidth={breakpoints.md}
                  sx={{
                    '.gradTitle': {
                      fontFamily: typography.fonts.body,
                      color: 'white',
                      position: 'relative',
                      fontWeight: '400',
                      background: `linear-gradient(to right, ${hexToRGB(color.alpha, 0.7)}, ${hexToRGB(color.alpha, 1)})`,
                      textShadow: '0px 1px 2px rgba(255,255,255,0.5)',
                      backgroundClip: 'text',
                    },
                    '.bigBlurb': {
                      maxWidth: '400px',
                      marginBottom: '0'
                    }
                  }}
                >
                  <H2 variant="figtreeSm" color={hexToRGB(colors.brand.slate, 0.3)}>{page?.s6SuperTitle}</H2>
                  <H2 className="gradTitle" variant="figtree">{page?.s6Title}</H2>
                </Box>
                <Box maxWidth={breakpoints.md} pb={[spacing.elements, null, spacing.groups]}>
                    <Accordion
                      allowToggle="true"
                      border="none"
                      sx={{
                        '.faqItem': {
                          border: 'none',
                          '& button': {
                            textAlign: 'left',
                            color: colors.brand.slate,
                            fontSize: 'inherit',
                            fontWeight: '600',
                            backgroundColor: 'transparent',
                            padding: '1rem',
                            display: 'flex',
                            justifyContent: 'space-between',
                            '&:focus': {
                              boxShadow: 'none',
                              background: 'none',
                            }
                          }
                        },
                      }}
                    >
                      {page.listFAQ.map((faq, i) => {
                        return (
                            <AccordionItem key={`faq-item-${i}`} className="faqItem">
                              <H6 marginBottom="0">
                                <AccordionButton>
                                  {faq.faqQuestion}<AccordionIcon />
                                </AccordionButton>
                              </H6>
                              <AccordionPanel pb={4}>
                                <Box as="p" maxW="80%">
                                  {faq.faqAnswer}
                                </Box>
                              </AccordionPanel>
                            </AccordionItem>
                        )
                      })}
                    </Accordion>
                </Box>
              </Container>
            }
          </Box>






        </Box>
      <style>{`.tl-edges {overflow: unset;}`}</style>



      <FooterPioneer fTitle={page.footerText} weather={data.openWeather} />
    </Layout>
  ) 
}

export const query = graphql`
  query service2024Query($id: String!) {
    page: service2024Json(id: { eq: $id }) {
      title
      hdrSuperTitle
      hdrTitle
      hdrIntroP
      vidThumb
      vidImg
      vidSpline
      serviceVideoUpload
      serviceVideoCCTrack
      hsPortalId
      hsFormId
      kicker
      photoCats
      reasons {
        icon
        title
        blurb
      }
      s2SuperTitle
      s2Title
      s2Blurb
      portSuperTitle
      portTitle
      portDescription
      port
      s3SuperTitle
      s3Title
      s3Quote
      s3Attr
      s3Cases {
        image
        title
        blurb
      }
      s3CaseMobile {
        image
        title
        blurb
        stat01
        stat02
      }
      s4SuperTitle
      s4Title
      s4Numbers {
        dropCap
        title
        blurb
      }
      s5SuperTitle
      s5Title
      listServices {
        title
        mdxService
      }
      priceTiersShow
      priceTiers {
        priceTierTitle
        priceTierMDX
        priceTierPrice
      }
      s6SuperTitle
      s6Title
      hubspot
      hubspotSuperTitle
      hubspotTitle
      hubspotBlurb
      listFAQ {
        faqQuestion
        faqAnswer
      }
      footerText
      seoFields {
        seoTitle
        seoDesc
        ogImage
      }
    }
    settings: contentJson(slug: {eq: "globalSettings"}) {
      phone
    }
    openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
`

export default ServicePost2024